import Nav from '@/common/components/layout/protected/navbar';
import { routeLinks } from '@/common/routes';
import { Outlet, useLocation } from 'react-router-dom';
import VirtualCardLayoutItems from './VirtualCardLayoutItems';
const paths = [
  {
    title: 'Payment',
    path: routeLinks.protected.payment.swap.records,
  },
  {
    title: 'Virtual Card',
    path: routeLinks.protected.payment.virtual_card.index,
  },
];

function PaymentModuleLayout() {
  const { pathname } = useLocation();
  return (
    <div className='flex flex-col gap-y-8 h-full'>
      <div className='flex items-center gap-x-11'>
        <div className='mb-1.5'>
          <Nav paths={paths} type='joint' />
        </div>
        {routeLinks.protected.payment.virtual_card.index === pathname ? (
          <VirtualCardLayoutItems />
        ) : null}
      </div>
      <Outlet />
    </div>
  );
}

export default PaymentModuleLayout;
