import { Outlet } from 'react-router-dom';
import { GenericLayoutProps } from './types';
import PaymentModuleLayout from '@/modules/protected/payment/components/layout';

function GenericLayout({ module }: GenericLayoutProps) {
  let template: any = null;
  switch (module) {
    case 'payment':
      template = <PaymentModuleLayout />;
      break;

    default:
      template = <Outlet />;
      break;
  }
  return <>{template}</>;
}

export default GenericLayout;
