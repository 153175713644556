import { Select, SelectItem } from '@/common/components/forms/select';
import { getCurrentChildPath, mergeClassName } from '@/common/utils';
import { ReactNode } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

type NavItems = {
  title: ReactNode | string;
  path: string;
  children?: { name: string; value: string }[];
  disabled?: boolean;
};

type Props = {
  paths: NavItems[];
  type?: 'stand-alone' | 'joint';
};

function Nav({ paths, type }: Props) {
  const naviagte = useNavigate();
  const pathname = useLocation().pathname;
  const pathsList = pathname.split('/');
  const path = pathsList[pathsList.length - 1];
  const selectHandler = (value: string) => {
    if (path === value) return;
    naviagte(value, { replace: true });
  };

  return (
    <nav className={mergeClassName(!type ? 'flex border-b ' : '')}>
      <ul
        className={mergeClassName(
          'flex items-center gap-x-12',
          type === 'joint' && 'bg-primary text-white w-max gap-0 rounded-2xl',
        )}>
        {paths.map((item) => (
          <li key={item.path} className='flex items-center'>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                mergeClassName(
                  isActive
                    ? 'group isActive flex items-center rounded-2xl'
                    : 'flex items-center',
                  isActive && item?.children ? 'bg-primary' : '',
                )
              }
              onClick={(e) => (item.disabled ? e.preventDefault() : null)}>
              {({ isActive }) => (
                <>
                  <span
                    className={mergeClassName(
                      'block',
                      !type
                        ? 'py-4 px-5 text-sm text-[#667085] font-medium'
                        : 'px-7 py-0.5 text-lg',
                      type === 'stand-alone'
                        ? 'group-[.isActive]:bg-primary group-[.isActive]:text-white group-[.isActive]:rounded-2xl'
                        : '',
                      type === 'joint' && 'rounded-2xl',
                      !type && isActive
                        ? 'group-[.isActive]:text-[#202223] relative before:absolute before:inset-0 before:top-auto before:h-1 before:bg-primary before:rounded-tl before:rounded-tr'
                        : '',
                      isActive && (item?.children || type === 'joint')
                        ? '!bg-[#084BCD]'
                        : '',
                    )}>
                    {item.title}
                  </span>
                  {item?.children && isActive && (
                    <Select
                      className='text-xs !bg-transparent !border-none !outline-none text-white h-auto'
                      value={getCurrentChildPath(item?.children, pathname)}
                      classNames={{}}
                      onChange={selectHandler}>
                      {item?.children?.map((val) => (
                        <SelectItem
                          key={val.name}
                          value={val.value}
                          className='text-xs'>
                          {val.name}
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Nav;
