import Button from '@/common/components/forms/button';
import { routeLinks } from '@/common/routes';
import { useNavigate } from 'react-router-dom';

function CreateCardBtn() {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(routeLinks.protected.payment.virtual_card.create);
  };

  return (
    <Button variant='secondary' className='self-start' onClick={clickHandler}>
      Create Card
    </Button>
  );
}

export default CreateCardBtn;
