import { iHandleChange, iHandleSubmit } from '@/common/types';
import CustomInput from '@/common/components/forms/input';
import * as Slt from '@/common/components/forms/select';
import CustomButton from '@/common/components/forms/button';
import { useState } from 'react';
import { IcountryList } from '@/modules/auth/types';
import { useAppDispatch, useAppSelector } from '@/store';
import { useEffectOnce } from '@/common/hooks/useEffectOnce';
import { getAllCountries } from '@/modules/auth/store/thunk';

const PageDictionary = {
  pageTitle: 'Register',
  heading: 'Welcome,  Merchant',
  subHeading: 'Input your details to Sign up your account',
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
  accountOwnerEmailAddress: 'Root Account Email (optional)',
  emailLabel: 'Email address',
  passwordLabel: 'Password',
  phoneLabel: 'Phone Number',
  countryLabel: 'Country',
  registerActionButton: 'Sign up',
  swithPage: {
    text: 'Already have an account? ',
    loginActionLink: 'Sign In',
  },
  multiAccount: {
    heading: 'Hello, Merchant',
    subHeading: 'Sign up new account',
  },
};

type Value = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  country: string;
  account_owner_email_address: string;
  phone_number: string;
};

type Props = {
  submitHandler: iHandleSubmit;
  changeHandler: iHandleChange;
  value: Value;
  loading: boolean;
  selectHandler: (val: string) => void;
};

function AddCountryForm({
  changeHandler,
  submitHandler,
  loading,
  value,
  selectHandler,
}: Props) {
  const [countiesList, setCountiesList] = useState<IcountryList>([]);
  const dispatch = useAppDispatch();
  const { subAccounts } = useAppSelector((state) => state.appState);

  useEffectOnce(() => {
    dispatch(getAllCountries())
      .unwrap()
      .then((res) => setCountiesList(res));
  });
  return (
    <>
      <header className='flex flex-col gap-y-3.5'>
        <h2 className='text-[3.25rem] font-semibold leading-[3.875rem] tracking-tighter'>
          {PageDictionary.multiAccount.heading}
        </h2>
        <p className='text-lg font-normal leading-[1.875rem] text-gray-600'>
          {PageDictionary.multiAccount.subHeading}
        </p>
      </header>
      <form
        className='flex flex-1 flex-col gap-y-4 self-stretch px-8 py-4'
        onSubmit={submitHandler}>
        <CustomInput
          name='first_name'
          type='text'
          labelText={PageDictionary.firstNameLabel}
          value={value.first_name}
          onChange={changeHandler}
          disabled={loading}
          required
        />
        <CustomInput
          name='last_name'
          type='text'
          labelText={PageDictionary.lastNameLabel}
          value={value.last_name}
          onChange={changeHandler}
          disabled={loading}
          required
        />
        <CustomInput
          name='email'
          type='email'
          labelText={PageDictionary.emailLabel}
          value={value.email}
          onChange={changeHandler}
          disabled={loading}
          autoComplete='register'
          required
        />
        <CustomInput
          name='password'
          type='password'
          labelText={PageDictionary.passwordLabel}
          value={value.password}
          onChange={changeHandler}
          disabled={loading}
          autoComplete='register'
          required
        />
        <CustomInput
          name='phone_number'
          labelText={PageDictionary.phoneLabel}
          value={value.phone_number}
          onChange={changeHandler}
          disabled={loading}
          autoComplete='register'
          required
        />
        <Slt.Select
          onChange={selectHandler}
          label={PageDictionary.countryLabel}
          labelClassName='text-base-2 mb-2 inline-block text-base font-medium'
          value={value.country}
          className='!rounded-xl pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600'
          contentClassName='!p-0 z-50 '
          required>
          {countiesList
            ?.filter(
              (country) =>
                !subAccounts.find((itm) => itm.country === country.name),
            )
            .sort((a, b) => a?.name?.localeCompare(b?.name))
            .map((itm) => (
              <Slt.SelectItem
                key={itm.alpha2}
                className='!px-0 first:!border-b'
                value={itm.name}>
                {itm.name}
              </Slt.SelectItem>
            ))}
        </Slt.Select>
        <div className='mt-3.5 flex flex-col gap-y-5 '>
          <CustomButton
            loading={loading}
            type='submit'
            className='h-11 self-start'>
            {PageDictionary.registerActionButton}
          </CustomButton>
        </div>
      </form>
    </>
  );
}

export default AddCountryForm;
