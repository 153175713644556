import { mergeClassName } from '@/common/utils';
import { Select, SelectProps, SelectStylesNames } from '@mantine/core';

type ClassNamesType = SelectStylesNames;

interface Props extends Omit<SelectProps, 'classNames'> {
  classNames?: Partial<Record<ClassNamesType, string>>;
}

function CustomSelect({ classNames, ...props }: Props) {
  return (
    <Select
      {...props}
      classNames={{
        ...classNames,
        input: mergeClassName(
          'md:text-sm h-[3rem] outline-none bg-[#F8FAFC]  transition-all duration-200 text-xs border  rounded-xl px-4 py-2 border-opacity-40 w-full',
          classNames?.input,
        ),
      }}
    />
  );
}

export default CustomSelect;
