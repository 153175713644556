import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import '@mantine/core/styles.css';
import { ToastContainer } from 'react-toastify';
import '@/assets/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { MantineProvider } from '@mantine/core';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MantineProvider>
      <Provider store={store.store}>
        <PersistGate persistor={store.persistor} loading={null}>
          <Router>
            <App />
            <ToastContainer />
          </Router>
        </PersistGate>
      </Provider>
    </MantineProvider>
  </React.StrictMode>,
);
