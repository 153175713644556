import { createAsyncThunk } from '@reduxjs/toolkit';
import customToast from '../../../common/components/CustomToast';
import auth from '../service';
import { IloginUser, IrequestOtp } from '../types';
import { clearData } from '@/common/service/storage';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (req: IloginUser, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .loginUser(req)
      .then((res: any) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (req: any, { rejectWithValue, fulfillWithValue }) => {
    // console.log(req);
    // return fulfillWithValue({});
    return auth
      .registerUser(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(req).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getOtp = createAsyncThunk(
  'auth/sendOtp',
  async (data: IrequestOtp, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .sendOtp(data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getCsrfToken = createAsyncThunk(
  'auth/csrfToken',
  async (data: IrequestOtp, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .csrfToken(data)
      .then((res: any) => {
        // customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: any, { fulfillWithValue, rejectWithValue }) => {
    return auth
      .resetPassword(data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const getAllCountries = createAsyncThunk(
  'auth/getAllCountries',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return auth
      .getAllCountries()
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  },
);
export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (token_expired?: boolean) => {
    // console.log('first');
    clearData();
    return Promise.resolve({ payload: null }).then(() => {
      // window.location.assign(routes.nonprotected.login);
      document.title = 'IntraPay';
      customToast(
        token_expired ? 'Token Expired' : 'Logout successfull',

        token_expired,
      );
    });
  },
);
// export const getAllCountries = async (
//   setCountiesList: React.Dispatch<React.SetStateAction<IcountryList>>
// ) => {
//   return auth
//     .getAllCountries()
//     .then((res) => {
//       setCountiesList(res.data);
//     })
//     .catch((err) => {
//       customToast(err.message, true);
//     });
// };
