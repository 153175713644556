import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from './header';
import { CONTAINER_CLASSNAME } from '@/common/helpers/container';
import { Sidebar } from './sidebar';
import { mergeClassName } from '@/common/utils';
import { fetchUserToken } from '@/common/service/storage';
import { routeLinks } from '@/common/routes';
import { useEffectOnce } from '@/common/hooks/useEffectOnce';
import { useAppDispatch, useAppSelector } from '@/store';
import { getInfoNew } from '@/modules/protected/overview/store/thunk';
import { getMerchantInfo } from '@/modules/protected/transactions/store/thunk';
import { useEffect } from 'react';
import { getBankInfoFunding } from '@/modules/protected/wallet/store/thunk';

type Props = {
  isFullScreen?: boolean;
};

export const ProtectedLayout = ({ isFullScreen }: Props) => {
  const userToken = fetchUserToken();
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);
  // const { is_loading: paymentConfigLoading, payment_config: paymentConfig } =
  //   useAppSelector((state) => state.wallet);
  const { kyc_level } = useAppSelector((state) => state.wallet.p2p_info);
  // const isWalletCreated = !!paymentConfig?.account_number;
  const { pathname } = useLocation();
  const { loading } = useAppSelector((state) => state.dashboard);
  const navigate = useNavigate();

  // gets the online state of the user and also the dashboard on load of application and on refresh
  useEffectOnce(() => {
    if (!userToken) return;
    dispatch(getInfoNew({ user_id }));
    dispatch(getMerchantInfo(user_id));
    dispatch(getBankInfoFunding(user_id));
  });

  // // isWalletCreated is disabled on the request of emcode
  // useEffect(() => {
  //   if (loading || kyc_level /* || isWalletCreated */) return;
  //   if (/* !paymentConfigLoading && !isWalletCreated || */ !kyc_level) {
  //     return navigate(routeLinks.protected.compliance.index, { replace: true });
  //   }
  // }, [isWalletCreated]); //eslint-disable-line

  useEffect(() => {
    if (loading) return;
    if (!kyc_level /* && isWalletCreated */) {
      return navigate(routeLinks.protected.compliance.kyc, { replace: true });
    }
    if (kyc_level === 'ACTIVE' /* && isWalletCreated */) {
      return navigate(routeLinks.protected.overview, { replace: true });
    }
  }, [kyc_level]); //eslint-disable-line

  if (!userToken) {
    return <Navigate to={routeLinks.auth.login} replace={true} />;
  }

  return (
    <>
      {isFullScreen ? (
        <Outlet />
      ) : (
        <div className='flex flex-col'>
          <Header />
          <div
            className={mergeClassName(
              CONTAINER_CLASSNAME,
              'flex justify-between relative ',
            )}>
            <div className='flex-[0.2] pt-6'>
              <Sidebar />
            </div>
            <div
              className={mergeClassName(
                'flex-[0.78] h-[calc(100vh-72px-24px)] overflow-scroll no-scrollbar px-3 py-5 mx-3 my-5 rounded-2xl',
                pathname.includes(routeLinks.protected.compliance.index)
                  ? 'shadow-stats'
                  : '',
              )}>
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
