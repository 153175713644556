import dayjs from 'dayjs';

export const greetUser = () => {
  const hour = dayjs().hour();

  let message = '';
  switch (true) {
    case hour < 12:
      message = 'Good Morning';
      break;
    case hour > 12 && hour < 16:
      message = 'Good Afternoon';
      break;

    default:
      message = 'Good Evening';
      break;
  }

  return message;
};

export const isNotGreaterThanToday = new Date(
  dayjs().endOf('day').toISOString(),
);
