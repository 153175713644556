import redux from '../../store';
import numeral from 'numeral';

export const addCommas = (num: string) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const removeNonNumeric = (num: string) =>
  num?.toString()?.replace(/[^0-9]/g, '');
export const removeCommas = (num: string) =>
  num?.toString()?.replace(/[, ]+/g, '')?.trim();

export const toCurrency = (amount: number) => {
  const country_data = redux.store.getState().appState.appState.country_data;
  // const country_data: any = redux.store.getState();

  const alpha2 = country_data?.alpha2;
  const language = country_data?.languages?.[0];
  const currency = country_data?.currencies?.[0];
  const code = `${language}-${alpha2}`;

  const data =
    alpha2 &&
    amount?.toLocaleString(code, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return data;
};

export const FormatNumber = (
  value: number | string,
  curr?: boolean,
): string => {
  if (typeof value === 'string') value = Number(value);

  const currency = curr ? '$' : '';
  return currency + numeral(value).format('0,0.00');
};

export const toDollar = (amount: number) => {
  const currency = amount.toLocaleString(`eng-US`, {
    style: 'currency',
    currency: `USD`,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return currency;
};

export const truncatedText = (text: string, maxlength: number) => {
  if (text?.length > maxlength) {
    return `${text?.substring(0, 3)}...${text?.substring(text?.length - 4)}`;
  }

  return text;
};
