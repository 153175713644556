import * as clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const mergeClassName = (...className: clsx.ClassValue[]) => {
  return twMerge(clsx.clsx(...className));
};

export const getCurrentChildPath = (
  paths: { name: string; value: string }[] = [],
  current: string,
) => {
  const foundPath = paths.find((path) => path.value === current)?.name;

  return foundPath || '';
};

export const queryHandler = (data: Record<string, string | number>): string => {
  let query = '?';

  const keys = Object.keys(data) as Array<keyof typeof data>;

  keys.forEach((key) => {
    if (data[key] !== '' || data[key] !== undefined || data[key] !== null) {
      query += `${key}=${data[key]}&`;
    }
  });

  return query.substring(0, query.length - 1);
};
