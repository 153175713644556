import { useState } from 'react';
import CenterModal from '@/common/components/layout/protected/centralmodal';
import { getCsrfToken, getOtp, registerUser } from '@/modules/auth/store/thunk';
import { iHandleChange, iHandleSubmit, VoidFunction } from '@/common/types';
import { useAppDispatch, useAppSelector } from '@/store';
import { useEffectOnce } from '@/common/hooks/useEffectOnce';
import { updateField } from '@/modules/auth/store/slice';
import AddCountryForm from './Form';
import customToast from '@/common/components/CustomToast';
import AddCountryEnterOtp from './EnterOtp';

const initialstate = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  country: '',
  account_owner_email_address: '',
  phone_number: '',
};

type Props = {
  closeModalHandler: VoidFunction;
  prefillData?: string;
};

function AddCountryModal({ closeModalHandler, prefillData }: Props) {
  const [stage, setStage] = useState('form');
  const dispatch = useAppDispatch();
  const [state, setState] = useState(initialstate);
  const { loading, auth: auth_state } = useAppSelector((state) => state?.auth);

  useEffectOnce(() => {
    if (prefillData) {
      setState((prev) => ({
        ...prev,
        account_owner_email_address: prefillData,
      }));
    }
  });

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const selectHandler = (value: string) => {
    setState((prev) => ({ ...prev, country: value }));
  };

  const backDropHandler = () => {
    if (loading) return;
    closeModalHandler();
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateField(state));
    dispatch(getCsrfToken({ email: state.email }))
      .unwrap()
      .then((res) => {
        dispatch(getOtp({ email: state.email, csrf_token: res }))
          .unwrap()
          .then(() => {
            setStage('otp');
          });
      });
  };

  const otpSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    dispatch(registerUser(auth_state))
      .unwrap()
      .then(() => {
        backDropHandler();
        setStage('form');
        customToast('New Account Added');
      });
  };

  return (
    <CenterModal className=' w-4/12 p-8' handleClose={backDropHandler}>
      {stage === 'form' ? (
        <AddCountryForm
          changeHandler={changeHandler}
          loading={loading}
          selectHandler={selectHandler}
          submitHandler={submitHandler}
          value={state}
        />
      ) : null}
      {stage === 'otp' ? (
        <AddCountryEnterOtp
          loading={loading}
          submitHandler={otpSubmitHandler}
          value={state}
        />
      ) : null}
    </CenterModal>
  );
}

export default AddCountryModal;
