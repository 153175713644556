import { ComplianceIcon } from '@/assets/icons/compliance-icon';
import { FiCalendar } from 'react-icons/fi';
import { SidebarNavigation } from './sidebar-navigation';
import { mainNavLinks } from './helper';
import { DocumentationIcon, VideoIcon } from '@/assets/icons';
import dayjs from 'dayjs';
import { greetUser } from '@/common/utils';
import MerchantId from './merchant-id';
import Support from './support';
import { useAppSelector } from '@/store';

export const Sidebar = () => {
  const { kyc_level } = useAppSelector((state) => state.wallet.p2p_info);
  // const paymentConfig = useAppSelector((state) => state.wallet.payment_config);
  // const isWalletCreated = !!paymentConfig?.account_number;
  const isWalletCreated = true;

  return (
    <div className='flex flex-col gap-y-5 w-full  h-full '>
      <div className='flex flex-col gap-y-2'>
        <p className='flex items-center gap-x-1 text-xs text-[#6F6F6F] font-medium'>
          <span>
            <FiCalendar className='text-lg' />
          </span>
          <span>{dayjs().format('dddd, MMM DD YYYY.')}</span>
        </p>
        <h1 className='text-[#6F6F6F] font-bold text-2xl'>{greetUser()}</h1>
      </div>
      <div className='flex flex-col gap-y-5 sticky top-3'>
        {!kyc_level || !isWalletCreated ? (
          <ComplianceButton />
        ) : (
          <SidebarNavigation
            className={{
              container: 'shadow-[0px_0px_25px_0px_#00000024]',
              header: 'text-[#6F6F6F]',
            }}
            title='Resources'
            variant='light'
            data={mainNavLinks}
          />
        )}
        <SidebarNavigation
          className={{
            container: 'shadow-[0px_0px_25px_0px_#00000024]',
            header: 'text-[#6F6F6F]',
          }}
          title='Resources'
          variant='light'
          target='_blank'
          data={[
            {
              id: 1,
              title: 'Documentation',
              icon: <DocumentationIcon />,
              path: 'https://docs.fuspay.finance/',
            },
            {
              id: 2,
              title: 'Watch Video',
              icon: <VideoIcon />,
            },
          ]}
        />
        <SidebarNavigation
          className={{
            container: 'shadow-[0px_0px_17.21px_0px_#00000026]',
            header: 'text-[#6F6F6F]',
          }}
          title='Account Support'
          variant='dark'
          target='_blank'
          data={[
            {
              id: 1,
              title: 'Chat Support',
              icon: <Support />,
              path: 'https://t.me/+BP2Os0XbyxU5OWZk',
            },
          ]}
        />
        <MerchantId />
      </div>
    </div>
  );
};

const ComplianceButton = () => {
  return (
    <div className=''>
      <button className='bg-primary gap-x-6 items-center text-white flex py-[1rem] px-[1.563rem] w-full rounded-xl text-xs font-medium shadow-[0px_0px_17.21px_0px_#0000001A]'>
        <span className='flex-shrink-0'>
          <ComplianceIcon />
        </span>
        <span>Compliance</span>
      </button>
    </div>
  );
};
