import CustomSelect from '@/common/components/CustomSelect';
import { mergeClassName } from '@/common/utils';
import CreateCardBtn from '../pages/virtualcards/components/OtherCardInfo/CreateCardBtn';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from '@/common/hooks/useEffectOnce';
import { convertParamsToObject } from '@/common/routes';

const cards = [
  {
    id: '1',
    name: 'AWS Card',
    value: '5564 3344 4533 8832',
  },
  {
    id: '2',
    name: 'My Netflix Card',
    value: '5564 3344 4533 8833',
  },
];
const statusList = [
  {
    name: 'Active Cards',
    value: 'isActive',
  },
  {
    name: 'Frozed Cards',
    value: 'isFrozen',
  },
  {
    name: 'Deleted Cards',
    value: 'isDeleted',
  },
];

type Props = {
  className?: string;
};

function VirtualCardLayoutItems({ className }: Props) {
  const [params, setParams] = useSearchParams();
  const cardInContext = params.get('_id');
  const cardStatus = params.get('status');

  const cardChangeHandler = (_id: string | null) => {
    if (!_id) return;
    setParams({ ...convertParamsToObject(params), _id });
  };
  const statusChangeHandler = (status: string | null) => {
    if (!status) return;
    setParams({ ...convertParamsToObject(params), status });
  };

  useEffectOnce(() => {
    const _id = cards[0]?.value;
    const status = statusList[0]?.value;
    setParams({ _id, status });
  });

  return (
    <div className={mergeClassName('flex items-center gap-x-2', className)}>
      <CustomSelect
        data={cards.map((item) => ({
          label: item.name,
          value: item.value,
          items: item,
        }))}
        className='h-10'
        classNames={{
          input: 'h-auto border-[#084BCD] text-[#084BCD] font-bold',
        }}
        value={cardInContext}
        onChange={cardChangeHandler}
      />
      <CustomSelect
        data={statusList.map((item) => ({
          label: item.name,
          value: item.value,
        }))}
        className='h-10'
        classNames={{
          input: 'h-auto border-none',
        }}
        value={cardStatus}
        onChange={statusChangeHandler}
      />
      <CreateCardBtn />
    </div>
  );
}

export default VirtualCardLayoutItems;
