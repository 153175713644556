import { lazy } from 'react';

export const loadModules = (module: string, page: string | string[]) => {
  const isArray = Array.isArray(page);

  let mainPath = page;
  if (page.length === 1 && isArray) {
    const [path1] = page;
    mainPath = path1;
  }

  if (page.length === 2 && isArray) {
    const [path1, path2] = page;
    return lazy(
      () => import(`../../modules/${module}/${path1}/pages/${path2}/index.tsx`),
    );
  }
  if (page.length === 3 && isArray) {
    const [path1, path2, path3] = page;
    return lazy(
      () =>
        import(
          `../../modules/${module}/${path1}/pages/${path2}/${path3}/index.tsx`
        ),
    );
  }
  if (page.length === 4 && isArray) {
    const [path1, path2, path3, path4] = page;
    return lazy(
      () =>
        import(
          `../../modules/${module}/${path1}/pages/${path2}/${path3}/${path4}/index.tsx`
        ),
    );
  }
  return lazy(
    () => import(`../../modules/${module}/${mainPath}/pages/index.tsx`),
  );
};
export const loadAuthModules = (page: string) => {
  return lazy(() => import(`../../modules/auth/pages/${page}/index.tsx`));
};

export const convertParamsToObject = (searchParams: URLSearchParams) => {
  const obj: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};
