import React from 'react';
import { Route } from 'react-router-dom';
import { RouteType } from './types';
import { AuthLayout } from '@/modules/auth/components/layout';
import { ProtectedLayout } from '../components/layout/protected';
import AppLoader from '../components/AppLoader';
import GenericLayout from './GenericLayout';

export const generateRoute = ({
  path,
  Component,
  access,
  layout,
}: RouteType) => {
  return (
    <Route
      key={`${path}`}
      element={
        access === 'public' ? (
          <AuthLayout />
        ) : (
          <ProtectedLayout isFullScreen={access === 'loggedin-fullscreen'} />
        )
      }>
      <Route element={<GenericLayout module={layout} />}>
        <Route
          path={path}
          element={
            <React.Suspense fallback={<AppLoader />}>
              <Component />
            </React.Suspense>
          }
        />
      </Route>
    </Route>
  );
};
